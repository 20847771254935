import type { AddressFragment, ChannelFragment, CollectionDetailFragment, CollectionListItemFragment, CountryFragment, CustomerFragment, DisputeFragment, FacetValueFragment, OrderDetailFragment, OrderFragment, PaymentMethodFragment, ProductDetailFragment, SearchResultFragment, ShippingMethodFragment } from '#graphql-operations'

export interface SearchState {
  [key: string]: {
    pages?: Record<string, string[]>
    search?: {
      totalItems: number
      facetValues: {
        count: number
        facetValue: FacetValueFragment
      }[]
    }
    searchFacetValues?: {
      totalItems: number
      // Make non-optional when the graphql operation has facetValues also uncommented
      facetValues?: {
        count: number
        facetValue: FacetValueFragment
      }[]
    }
  } | undefined
}

export interface ActiveCustomerOrdersState {
  [key: string]: {
    pages?: Record<string, string[]>
    activeCustomer?: {
      id?: string
      orders?: {
        totalItems: number
      }
    }
  }
}

export interface ActiveCustomerDisputesState {
  [key: string]: {
    pages?: Record<string, string[]>
    disputes?: {
      totalItems: number
    }
  }
}

export interface StoreState {
  [key: string]: any

  // activeChannel
  activeChannel?: ChannelFragment | undefined | null

  // activeCustomer
  activeCustomer?: CustomerFragment | undefined | null
  addresses?: AddressFragment[] | undefined | null

  // activeOrder
  activeOrder?: OrderDetailFragment | undefined | null

  // order (guest checkout)
  activeCustomerOrders?: ActiveCustomerOrdersState
  ordersItems?: Record<string, OrderFragment | undefined | null>
  order?: Record<string, OrderDetailFragment | undefined | null>

  // availableCountries
  availableCountries?: CountryFragment[] | undefined | null

  // eligibleShippingMethods
  eligibleShippingMethods?: ShippingMethodFragment[] | undefined | null

  // eligiblePaymentMethods
  eligiblePaymentMethods?: PaymentMethodFragment[] | undefined | null

  // collections
  collections?: CollectionListItemFragment[] | undefined | null
  collection?: Record<string, CollectionDetailFragment | undefined | null>

  // product feed data
  search?: SearchState
  items?: Record<string, SearchResultFragment | undefined | null>

  product?: Record<string, ProductDetailFragment | undefined | null>
  facetValues?: FacetValueFragment[] | undefined | null
  facetValue?: Record<string, FacetValueFragment | undefined | null>

  // stripe
  stripePaymentIntent?: string | null

  // disputes
  activeCustomerDisputes?: ActiveCustomerDisputesState
  disputeItems?: Record<string, DisputeFragment | undefined | null>
  dispute?: Record<string, DisputeFragment | undefined | null>
}

export function useStore() {
  return useState<StoreState>('store', () => ({}))
}
